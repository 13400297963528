* {
  padding: 0px;
  margin: 0px;
  font-family: Nunito;
}

html {
  background: #383838;
  overflow-x: hidden;
}

label, h1, h2, h3, h4, h4, span {
  color: black;
  font-family: Nunito;
}

textarea {
  resize: none;
  border: none;
  outline: none;
  background: #ffffff;
  border-radius: 4px;
  padding: 10px;
}

input {
  border: none;
  outline: none;
}

.animated-linear {
  -webkit-transition: background-color 270ms linear;
  -ms-transition: background-color 270ms linear;
  transition: background-color 270ms linear;
  transition: all ease-in-out 270ms;
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.del {
  animation: fade-out-animation 0.8s cubic-bezier(0.65, -0.02, 0.72, 0.29);
}

@keyframes fade-out-animation {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  30% {
    opacity: 1;
    transform: translateX(50px);
  }
  80% {
    opacity: 1;
    transform: translateX(-800px);
  }
  100% {
    opacity: 0;
    transform: translateX(-800px);
  }
}