.custom-input-container {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  height: 25px;
  text-transform: capitalize;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-input-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.custom-input-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 22px;
  background: #ffffff;
  border-radius: 3px;
  border: 1px solid #b3b3b3;
}

/* On mouse-over, add a grey background color */
.custom-input-container:hover input ~ .custom-input-checkmark {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
}

/* When the checkbox is checked, add a blue background */
.custom-input-container input:checked ~ .custom-input-checkmark {
  background: #ffffff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.custom-input-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-input-container input:checked ~ .custom-input-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-input-container .custom-input-checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #da3546;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
