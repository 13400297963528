/* The container */
.container-radio-button-component {
  display: flex;
  position: relative;
  padding-left: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 500;
  font-size: 16px;
  color: #6a6a6a;
}
.label-radio-button {
  font-weight: bold;
  font-size: 14px;
  color: #6a6a6a;
  text-transform: uppercase;
}

/* Hide the browser's default radio button */
.container-radio-button-component input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  z-index: 0;
  top: 2px;
  left: 0;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: #ffffff;
  border: 1px solid #dde2e5;
  cursor: pointer;
}

/* On mouse-over, add a grey background color */
.container-radio-button-component .checkmark:hover {
  background-color: #dde2e5;
}

/* When the radio button is checked, add a blue background */
.container-radio-button-component input:checked ~ .checkmark {
  background-color: transparent;
  border: 1px solid #aaa8ab;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-radio-button-component input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-radio-button-component .checkmark:after {
  width: 9px;
  height: 9px;
  background: #d62645;
  border-radius: 14px;
  top: 3px;
  left: 3px;
  border-radius: 50%;
}
