@font-face {
  font-family: "revicons";
  fallback: fallback;
  src: url("./revicons.woff") format("woff"), url("./revicons.ttf") format("ttf"), url("./revicons.eot") format("ttf");
}

.react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  max-width: 98vw;
}

.react-multi-carousel-track {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform, transition;
}

.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 10;
  border: 0;
  background: transparent;
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
}

.react-multiple-carousel__arrow:hover {
  background: transparent;
}

.react-multiple-carousel__arrow::before {
  font-size: 25px;
  color: #e94e5c;
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: 2;
  position: relative;
}

.react-multiple-carousel__arrow--left {
  left: 0px;
}

.react-multiple-carousel__arrow--left::before {
  content: "\e824";
}

.react-multiple-carousel__arrow--right {
  right: 0px;
}

.react-multiple-carousel__arrow--right::before {
  content: "\e825";
}

.react-multi-carousel-dot-list {
  position: absolute;
  bottom: -5px;
  display: flex;
  left: 0;
  right: 0;
  justify-content: center;
  margin: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.react-multi-carousel-dot button {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  box-shadow: none;
  transition: background 0.5s;
  padding: 0;
  margin: 0;
  margin-right: 6px;
  outline: 0;
  cursor: pointer;
  border: none;
  background: #e94e5c;
}

.react-multi-carousel-dot button:hover {
  background: #e94e5c;
}

.react-multi-carousel-dot--active button {
  width: 9px;
  height: 9px;
}

.react-multi-carousel-item {
  transform-style: preserve-3d;
  backface-visibility: hidden;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .react-multi-carousel-item {
    flex-shrink: 0 !important;
  }
  .react-multi-carousel-track {
    overflow: visible !important;
  }
}